import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import { getDiscountPrice } from "../../helpers/product";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import useTranslationHook from "../../hooks/useTranslationHook";
import {
  addToCart,
  decreaseQuantity,
  deleteFromCart,
} from "../../store/slices/cart-slice";
import { cartItemStock } from "../../helpers/product";
import {
  getCustomerInfoFromStroge,
  numberWithCommas,
} from "../../shared/helpersMethod";
import { IALLPRODUCTS, IOTPCUSTRES } from "../../shared/commonUtils";
import MobileCartItems from "./components/MobileCartItems";
import ButtonCommon from "../../components/button-common/ButtonCommon";
import { useAppSelector } from "../../hooks/useReduxMethods";
import { processColor } from "../../shared/processColor";
import ApplyCoupon from "../../components/applyCoupon/ApplyCoupon";
import SendOtp from "../../components/modal/SendOtp/SendOtp";
import PopPageValidateOtp from "../../components/modal/ValidateOtp/ValidateOtp";
import CreateNewCustomer from "../../components/modal/createNewCustomer/CreateNewCustomer";
const Cart = () => {
  let cartTotalPrice = 0;
  const navigation = useNavigate();
  const customerInfo = getCustomerInfoFromStroge();
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const [quantityCount] = useState(1);
  const dispatch = useDispatch();
  const { t } = useTranslationHook();
  const { pathname } = useLocation();
  const [paymentBgColor, setPaymentBgColor] = useState({
    backgroundColor: "",
    borderTopColor: "",
  });
  const [paymentBorderTop, setPaymentBorderTop] = useState<string>("");
  const currency = useAppSelector((state) => state.currency);
  //@ts-ignore
  const { cartItems } = useSelector((state) => state.cart);
  //new logic //

  const [showValidateModal, setShowValidate] = useState<boolean>(false);
  const [showValidateOtpModal, setShowOtpValidate] = useState<boolean>(false);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const [customerMobile, setCustomerMobile] = useState<string>("");
  const [userCountryCode, setCountryCode] = useState<string>("");
  const navigateToCheckout = () => {
    if (null === customerInfo) {
      showValidateModalHandler();
      return;
    }
  };
  const showValidateModalHandler = () => {
    setShowValidate(true);
  };
  const onRequestSendOtpClose = (countryCode: any) => {
    setShowValidate(false);
    if (countryCode !== null) {
      showValidateOtpModalHandler();
      setCountryCode(countryCode);
    }
  };
  const showValidateOtpModalHandler = () => {
    setShowOtpValidate(true);
  };
  const onRequestValidateOtpModalClose = () => {
    setShowOtpValidate(false);
    showValidateModalHandler();
  };
  const showCustomerModalHandler = () => {
    setShowCustomerModal(true);
  };
  const onRequestCustomerModalClose = () => {
    setShowCustomerModal(false);
  };
  const onSuccessValidateOtp = (isNew: any, customerData: IOTPCUSTRES) => {
    setShowOtpValidate(false);
    setShowValidate(false);
    if (customerData.name === "New") {
      showCustomerModalHandler();
      return;
    }
    if (isNew) {
      showCustomerModalHandler();
      return;
    }
    // todo
    // loadAddress(customerData.id);
  };
  //new logic end //
  return (
    <Fragment>
      <SEO
        titleTemplate="Cart"
        description="Cart page of site"
        canonicalLink={"/cart"}
      />

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: t("txt_home"), path: process.env.PUBLIC_URL + "/" },
            { label: t("txt_cart"), path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div className="cart-main-area pt-90- pb-100">
          <div className="container">
            {cartItems && cartItems.length >= 1 ? (
              <Fragment>
                <div className="row">
                  <div className="col-12">
                    {/* desktop view  */}
                    <div className="table-content table-responsive cart-table-content d-none d-lg-block">
                      <table>
                        <thead>
                          <tr>
                            <th>{t("txt_cart_image")}</th>
                            <th>{t("txt_cart_product_name")}</th>
                            <th>{t("txt_cart_unit_price")}</th>
                            <th>{t("txt_quantity")}</th>
                            <th>{t("txt_cart_subtotal")}</th>
                            <th>{t("txt_cart_action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.map((cartItem, key) => {
                            const discountedPrice = getDiscountPrice(
                              cartItem.price,
                              cartItem.discount
                            );
                            const finalProductPrice = (
                              cartItem.price * currency.currencyRate
                            ).toFixed(2);
                            const finalDiscountedPrice = (
                              discountedPrice! * currency.currencyRate
                            ).toFixed(2);

                            discountedPrice != null
                              ? (cartTotalPrice +=
                                  finalDiscountedPrice * cartItem.quantity)
                              : (cartTotalPrice +=
                                  finalProductPrice * cartItem.quantity);
                            return (
                              <tr key={key}>
                                <td className="product-thumbnail">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/product/" +
                                      cartItem.id
                                    }
                                  >
                                    <img
                                      className="img-fluid"
                                      src={
                                        process.env.PUBLIC_URL +
                                        cartItem.image[0]
                                      }
                                      alt="cart-img"
                                    />
                                  </Link>
                                </td>

                                <td className="product-name">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/product/" +
                                      cartItem.id
                                    }
                                  >
                                    {cartItem.name}
                                  </Link>
                                  {cartItem.selectedLableValue &&
                                  Object.keys(cartItem.selectedLableValue)
                                    .length > 0 ? (
                                    <div className="cart-item-variation">
                                      <span>
                                        {
                                          cartItem.selectedLableValue.firstLabel
                                            .key
                                        }
                                        :{" "}
                                        {
                                          cartItem.selectedLableValue.firstLabel
                                            .value
                                        }
                                      </span>
                                      {cartItem.selectedLableValue
                                        .secondLabel && (
                                        <span>
                                          {
                                            cartItem.selectedLableValue
                                              .secondLabel.key
                                          }
                                          :{" "}
                                          {
                                            cartItem.selectedLableValue
                                              .secondLabel.value
                                          }
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>

                                <td className="product-price-cart">
                                  {discountedPrice !== null ? (
                                    <Fragment>
                                      <span className="amount old">
                                        {numberWithCommas(finalProductPrice) +
                                          currency.currencySymbol}
                                      </span>
                                      <span className="amount">
                                        {numberWithCommas(
                                          finalDiscountedPrice
                                        ) + currency.currencySymbol}
                                      </span>
                                    </Fragment>
                                  ) : (
                                    <span className="amount ">
                                      <span className="ml-2 mr-2">
                                        {numberWithCommas(finalProductPrice)}
                                      </span>

                                      <span>{currency.currencySymbol}</span>
                                    </span>
                                  )}
                                </td>

                                <td className="product-quantity">
                                  <div className="cart-plus-minus">
                                    <button
                                      className="dec qtybutton"
                                      onClick={() =>
                                        dispatch(
                                          decreaseQuantity({
                                            ...cartItem,
                                            translation:
                                              t("txt_decreased_cart"),
                                          })
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      className="cart-plus-minus-box"
                                      type="text"
                                      value={cartItem.quantity}
                                      readOnly
                                    />
                                    <button
                                      className="inc qtybutton"
                                      onClick={() =>
                                        dispatch(
                                          addToCart({
                                            ...cartItem,
                                            quantity: quantityCount,
                                            translation: t("txt_added_to_cart"),
                                          })
                                        )
                                      }
                                      disabled={
                                        cartItem !== undefined &&
                                        cartItem.quantity &&
                                        cartItem.quantity >=
                                          cartItemStock(
                                            cartItem,
                                            cartItem.selectedProductColor,
                                            cartItem.selectedProductSize
                                          )
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>
                                <td className="product-subtotal">
                                  {discountedPrice !== null
                                    ? numberWithCommas(
                                        finalDiscountedPrice *
                                          cartItem.quantity.toFixed(2)
                                      ) +
                                      " " +
                                      currency.currencySymbol
                                    : numberWithCommas(
                                        (
                                          finalProductPrice * cartItem.quantity
                                        ).toFixed(2)
                                      ) +
                                      " " +
                                      currency.currencySymbol}
                                </td>

                                <td className="product-remove">
                                  <button
                                    onClick={() =>
                                      dispatch(
                                        deleteFromCart(cartItem.cartItemId)
                                      )
                                    }
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* desktop view  end */}

                    {/* mobile view  */}
                    <div className="cart-item-mobile  d-lg-none">
                      <div className="item-container">
                        {cartItems &&
                          cartItems.map((item, index: number) => {
                            return (
                              <MobileCartItems
                                onPressDecrease={(cartItem) => {
                                  dispatch(
                                    decreaseQuantity({
                                      ...cartItem,
                                      translation: t("txt_decreased_cart"),
                                    })
                                  );
                                }}
                                onPressDelete={(cartItem) =>
                                  dispatch(
                                    deleteFromCart({
                                      cartItemId: cartItem.cartItemId,
                                      translation: t("txt_added_to_cart"),
                                    })
                                  )
                                }
                                onPressIncrease={(cartItem) => {
                                  dispatch(
                                    addToCart({
                                      ...cartItem,
                                      quantity: quantityCount,
                                      translation: t("txt_added_to_cart"),
                                    })
                                  );
                                }}
                                cartItem={item}
                              />
                            );
                          })}
                      </div>
                      <div className="payment-details">
                        <span className="arabic-bold txt-order-summary">
                          {t("order_summary")}
                        </span>
                        <div className="payment-row">
                          <span className="title">
                            {t("txt_total_products")}
                          </span>
                          <span className="bold">
                            {numberWithCommas(cartTotalPrice.toFixed(2)) +
                              " " +
                              currency.currencySymbol}
                          </span>
                        </div>
                        {/* <div className="payment-row">
                          <span>Delivery Charges</span>
                          <span>FREE</span>
                        </div> */}
                        <ApplyCoupon />
                        <div className="payment-row total">
                          <span className="title">{t("txt_grand_total")} </span>
                          <span className="bold">
                            {numberWithCommas(cartTotalPrice.toFixed(2)) +
                              " " +
                              currency.currencySymbol}
                          </span>
                        </div>
                      </div>

                      <ButtonCommon
                        navigationLink={
                          null !== customerInfo &&
                          process.env.PUBLIC_URL + "/checkout"
                        }
                        onPressButton={navigateToCheckout}
                        btnLabelText={t("txt_proceed_to_checkout_new")}
                      />
                    </div>
                    {/* mobile view  */}
                  </div>
                </div>
                {/* Modals */}
                {showValidateModal && (
                  <SendOtp
                    setCustomerMobile={(mobile) => setCustomerMobile(mobile)}
                    show={showValidateModal}
                    onCloseModal={() => onRequestSendOtpClose(null)}
                    onPressSend={(countryCode) => {
                      onRequestSendOtpClose(countryCode);
                    }}
                  />
                )}
                {showValidateOtpModal && (
                  <PopPageValidateOtp
                    userMobileNumber={customerMobile}
                    show={showValidateOtpModal}
                    countryCode={userCountryCode}
                    onCloseModal={() => {
                      onRequestValidateOtpModalClose();
                    }}
                    onPressSend={(isNew, customerData) => {
                      onSuccessValidateOtp(isNew, customerData);
                    }}
                  />
                )}
                {showCustomerModal && (
                  <CreateNewCustomer
                    show={showCustomerModal}
                    onCloseModal={onRequestCustomerModalClose}
                  />
                )}
                {/* Modals End */}
                <div className="row d-none d-lg-block ">
                  <div className="col-lg-4 col-md-12">
                    <div className="grand-totall">
                      <div className="title-wrap">
                        <h4 className="cart-bottom-title section-bg-gary-cart">
                          {t("txt_cart_total")}
                        </h4>
                      </div>
                      <h5 className="d-flex">
                        <div> {t("txt_total_products")}</div>{" "}
                        <span className="pl-15 pr-15">
                          {numberWithCommas(cartTotalPrice.toFixed(2)) +
                            " " +
                            currency.currencySymbol}
                        </span>
                      </h5>

                      <h4 className="grand-totall-title d-flex">
                        <div> {t("txt_grand_total")} </div>
                        <span className="pl-15 pr-5">
                          {numberWithCommas(cartTotalPrice.toFixed(2)) +
                            " " +
                            currency.currencySymbol}
                        </span>
                      </h4>

                      <Link
                        onClick={navigateToCheckout}
                        to={
                          null !== customerInfo &&
                          process.env.PUBLIC_URL + "/checkout"
                        }
                      >
                        {t("txt_proceed_to_checkout_new")}
                      </Link>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="row ">
                <div className="col-lg-12 d-flex align-items-center justify-content-center">
                  <div className="item-empty-area text-center pt-70 pb-70">
                    <div className="item-empty-area__icon mb-30">
                      <i className="pe-7s-cart"></i>
                    </div>
                    <div className="item-empty-area__text pb-30 ml-30 mr-30">
                      {t("no_items_in_cart")} <br />{" "}
                      {/* <Link to={process.env.PUBLIC_URL + "/"}>
                        {t("txt_shop_now")}
                      </Link> */}
                    </div>

                    <div className="ml-30 mr-30">
                      <ButtonCommon
                        btnLabelText={t("txt_shop_now")}
                        navigationLink={process.env.PUBLIC_URL + "/"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Cart;
