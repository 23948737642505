import PropTypes from "prop-types";
import React, { Fragment } from "react";
import ProductGridSingleThree from "../../components/product/ProductGridSingleThree";
import { Product } from "../../shared/commonUtils";
import { useAppSelector } from "../../hooks/useReduxMethods";
interface IProductGridThree {
  selectedTabProducts: Product[];
  spaceBottomClass?: any;
  category?: any;
  type?: any;
  limit: any;
}
const ProductGridThree: React.FC<IProductGridThree> = (
  props: IProductGridThree
) => {
  const { spaceBottomClass, selectedTabProducts } = props;
  const currency = useAppSelector((state) => state.currency);
  const { cartItems } = useAppSelector((state) => state.cart);
  const { wishlistItems } = useAppSelector((state) => state.wishlist);
  const { compareItems } = useAppSelector((state) => state.compare);

  return (
    <Fragment>
      {selectedTabProducts &&
        selectedTabProducts?.map((product) => {
          return (
            <div className="custom2-col-5 product-single-wrap">
              <ProductGridSingleThree
                spaceBottomClass={spaceBottomClass}
                product={product}
                currency={currency}
                cartItem={cartItems.find(
                  (cartItem) => cartItem.id === product.id
                )}
                wishlistItem={wishlistItems.find(
                  (wishlistItem) => wishlistItem.id === product.id
                )}
                compareItem={compareItems.find(
                  (compareItem) => compareItem.id === product.id
                )}
              />
            </div>
          );
        })}
    </Fragment>
  );
};

ProductGridThree.propTypes = {
  spaceBottomClass: PropTypes.string,
};

export default ProductGridThree;
