import { toast } from "react-toastify";
import { ICUSTOMERINFO } from "./commonUtils";

const setBussinessToken = (newToken: string) => {
  localStorage.setItem(
    "bussinessData",
    JSON.stringify({
      accessToken: newToken,
    })
  );
};

const getBussinessData = () => {
  var data = localStorage.getItem("bussinessData");
  if (data !== null) {
    return JSON.parse(data);
  }
  return null;
};

const checkBussinessToken = async () => {
  var bussinessData = await getBussinessData(); // Wait for the data to be fetched

  if (bussinessData === null) {
    // If data is null, try again after 1 second
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
    bussinessData = await getBussinessData(); // Fetch again
  }
  return bussinessData;
};
export const getFinalTotalPrice = (
  totalValue: number,
  deliveryFee: any,
  monthlyInstallmentValue: number
) => {
  var finalValue = totalValue;
  if (monthlyInstallmentValue > 0) {
    finalValue = monthlyInstallmentValue + parseFloat(deliveryFee);
    return numberWithCommas(finalValue.toFixed(2));
  }
  if (null !== deliveryFee && deliveryFee) {
    finalValue = totalValue + parseFloat(deliveryFee);
  }
  return numberWithCommas(finalValue.toFixed(2));
};
const setCustomerInfoFromStroge = (customerInfo: ICUSTOMERINFO) => {
  localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
};
const getCustomerInfoFromStroge = () => {
  var localValue = localStorage.getItem("customerInfo");
  if (localValue !== null) {
    return JSON.parse(localValue);
  } else {
    return null;
  }
};

const saveUserPrefersLang = (lang: string) => {
  localStorage.setItem("userLanguage", lang);
};

export const UpdateBussinessInfo = (bussinessData: any) => {
  if (bussinessData && bussinessData !== null) {
    const favicon = document.getElementById("favicon");
    //@ts-ignore
    favicon.href = bussinessData?.site_settings?.siteFavicon;
  }

  //  businessSiteLogo
};
const getUserPrefersLang = () => {
  var language = localStorage.getItem("userLanguage");
  return language;
};
function numberWithCommas(x: number | string) {
  let num = Math.round(Number(x));
  let usFormat = num.toLocaleString("en-US");
  return usFormat;
}

function makeRtlCss() {
  document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
  document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
}
const TOKEN_EXPIRIES_MESSAGE = [
  "No token is found with request!",
  "No user found against this token!",
];

export {
  checkBussinessToken,
  TOKEN_EXPIRIES_MESSAGE,
  makeRtlCss,
  numberWithCommas,
  setCustomerInfoFromStroge,
  setBussinessToken,
  getBussinessData,
  getCustomerInfoFromStroge,
  saveUserPrefersLang,
  getUserPrefersLang,
};

export const showErrortMessage = (message: string) => {
  toast.error(message, {
    position: "top-center",
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    toastId: "error",
  });
};
export const showSuccesstMessage = (message: string) => {
  toast.success(message, {
    position: "top-center",
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    toastId: "success",
  });
};
