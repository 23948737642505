import React from "react";
import { CloseModalSvg } from "../../../assets/svgs/svgs";
interface IMODALCLOSE {
  onPressCross: () => void;
}
const ModalCloseButton: React.FC<IMODALCLOSE> = (props: IMODALCLOSE) => {
  const { onPressCross } = props;
  return (
    <div className="cross-button" onClick={onPressCross}>
      <CloseModalSvg />
    </div>
  );
};

export default ModalCloseButton;
